import React from "react";

import { FaDiscord, FaTwitter } from "react-icons/fa";

import Dot from "../components/Dot";

const HomePage = () => {
    return (
        <div className={"container mx-auto"}>
            <Cover />
            <OGZToken />
            <Updates />
        </div>
    );
};

const Cover = () => {
    return (
        <div className={"h-screen flex flex-col justify-center items-end font-serif"}>
            <div
                className={
                    "text-5xl ms:text-6xl md:text-7xl lg:text-8xl xl:text-9xl leading-snug lg:leading-snug xl:leading-snug font-serif"
                }
            >
                Oh..Geez
                <span className={"text-secondary title-xl"}>.</span>
            </div>
            <div className={"mt-8 title text-right"}>this is all jokes</div>
            <div className={"title text-right"}>don&apos;t take it too seriously</div>
        </div>
    );
};

const OGZToken = () => {
    return (
        <div className={"mt-96"}>
            <div className={"flex "}>
                <span className={"title-xl text-right"}>OGZ Token</span>
                <Dot />
            </div>
            <div className={"body mt-16"}>
                You can migrate LEVX tokens to OGZ, which will be used as native currency in upcoming projects like
                Sharkpunks Game and levx.io
            </div>
        </div>
    );
};

const Updates = () => {
    return (
        <div className={"mt-96"}>
            <div className={"flex justify-end items-end"}>
                <span className={"title-xl text-right"}>Cum join our loony bin</span>
                <Dot />
            </div>
            <div className={"body mt-16 text-right"}>
                So, you reckon you&apos;e a dimwit, or maybe even nuts? Perfect, you&apos;re exactly the disaster we
                need for this train wreck of a community.
            </div>
            <video
                className={"w-2/3 md:w-1/2 mt-16 mx-auto"}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
            >
                <source src={"video/vision.mp4"} type={"video/mp4"} />
            </video>
            <div className={"flex justify-center mt-16"}>
                <button
                    className={"btn btn-lg btn-twitter flex"}
                    onClick={() => window.open("https://twitter.com/LEVXDAOhGeez")}
                >
                    <FaTwitter fontSize={18} className={"mr-2"} />
                    <span className={"inline md:hidden"}>Twitter</span>
                    <span className={"hidden md:inline"}>Follow on Twitter</span>
                </button>
                <button
                    className={"btn btn-lg btn-discord ml-4 flex"}
                    onClick={() => window.open("https://discord.gg/ohgeez")}
                >
                    <FaDiscord fontSize={18} className={"mr-2"} />
                    <span className={"inline md:hidden"}>Discord</span>
                    <span className={"hidden md:inline"}>Join the Discord</span>
                </button>
            </div>
        </div>
    );
};

export default HomePage;
