import React from "react";

import ReactLoading from "react-loading";

const Loading = () => {
    return (
        <div className={"flex justify-center mt-8"}>
            <ReactLoading type={"cylon"} color="white" />
        </div>
    );
};

export default Loading;
