import React, { useEffect, useState } from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { Mainnet, DAppProvider, Config } from "@usedapp/core";
import { providers } from "ethers";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import { ALCHEMY_API_KEY } from "./constants";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import Verify from "./pages/Verify";

TimeAgo.addDefaultLocale(en);

const config: Config = {
    autoConnect: true,
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
        [Mainnet.chainId]: new providers.AlchemyProvider(1, ALCHEMY_API_KEY),
    },
};

const onRedirect = (): void => {
    window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
};

function App() {
    const [multiplier, setMultiplier] = useState<number>();
    useEffect(() => {
        if (window.location.hostname.includes("levxdao.org")) {
            window.location.href = "https://ohgeez.org";
        }
    }, []);
    useEffect(() => {
        const listener = () => {
            setMultiplier(0.5 + (window.screen.height - window.scrollY) / window.screen.height);
        };
        window.addEventListener("scroll", listener);
        return () => window.removeEventListener("scroll", listener);
    }, []);
    return (
        <DAppProvider config={config}>
            <Auth0Provider
                domain="dev-mlyd9xws.us.auth0.com"
                clientId="6nnaUhBzeI4cmkxGqwRFcH8EYMjSnItA"
                onRedirectCallback={onRedirect}
            >
                <HashRouter basename={process.env.PUBLIC_URL}>
                    <div>
                        <Header multiplier={multiplier || 1.5} />
                        <ScrollToTop />
                        <Routes>
                            <Route path="/verify" element={<Verify />} />
                            <Route path="/404" element={<NotFound />} />
                            <Route path="/" element={<HomePage />} />
                            <Route path="*" element={<Navigate to={"/"} replace={true} />} />
                        </Routes>
                        <Footer />
                        <ToastContainer />
                    </div>
                </HashRouter>
            </Auth0Provider>
        </DAppProvider>
    );
}

export default App;
