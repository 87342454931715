import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useEthers } from "@usedapp/core";
import { useAsyncEffect } from "use-async-effect";
import useLocalStorage from "use-local-storage";

import { API_SERVER } from "../constants";
import { getConnection } from "../utils/auth0-utils";

export interface VerifyState {
    loading: boolean;
    error?: string;
    setError: (error?: string) => void;
    discordIntegration?: SocialIntegration;
    socialIntegration?: SocialIntegration;
    verification: Verification | null;
    discordAccessToken: string | null;
    onVerify: () => Promise<void>;
    onIntegrateDiscord: () => Promise<void>;
    integratingDiscord: boolean;
    onIntegrateSocial: () => Promise<void>;
    integratingSocial: boolean;
}

export interface Verification {
    address: string;
    hash: string;
}

export interface SocialIntegration {
    address: string;
    id: string;
    domain: string | null;
    twitterId: string | null;
}

const useVerifyState = (): VerifyState => {
    const { isLoading, user, getAccessTokenSilently } = useAuth0();
    const { library, account } = useEthers();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [verification, setVerification] = useLocalStorage<Verification | null>("verification", null);
    const [discordAccessToken, setDiscordAccessToken] = useLocalStorage<string | null>("discord_access_token", null);
    const [discordIntegration, setDiscordIntegration] = useState<SocialIntegration>();
    const [socialIntegration, setSocialIntegration] = useState<SocialIntegration>();
    const [integratingDiscord, setIntegratingDiscord] = useState(false);
    const [integratingSocial, setIntegratingSocial] = useState(false);

    useEffect(() => {
        setSocialIntegration(undefined);
        if (account && verification && verification.address.toLowerCase() != account.toLowerCase()) {
            setVerification(null);
        }
    }, [verification, account]);

    useAsyncEffect(async () => {
        if (!isLoading) {
            if (user) {
                if (getConnection(user) == "discord") {
                    setDiscordAccessToken(await getAccessTokenSilently());
                }
            } else {
                setVerification(null);
                setDiscordAccessToken(null);
            }
        }
    }, [isLoading, user]);

    const onVerify = async () => {
        if (library) {
            const signer = library.getSigner();
            const hash = await signer.signMessage("I want to verify myself as " + account?.toLowerCase());
            setVerification({
                address: account?.toLowerCase() || "",
                hash,
            });
        }
    };

    const onIntegrateDiscord = async () => {
        if (user && discordAccessToken && verification) {
            setIntegratingDiscord(true);
            setError(undefined);
            try {
                const resp = await fetch(
                    API_SERVER +
                        `discord-integration/?access_token=${discordAccessToken}&hash=${verification.hash}&address=${verification.address}`,
                    { method: "POST" }
                );
                const json = await resp.json();
                if (resp.ok) {
                    if ((json as SocialIntegration).domain) {
                        setDiscordIntegration(json);
                    } else {
                        setError("You don't own any levx.eth subdomain.");
                    }
                } else {
                    setError(json.errors[0].message);
                }
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setIntegratingDiscord(false);
            }
        }
    };

    const onIntegrateSocial = async () => {
        if (user && getConnection(user) == "twitter" && discordAccessToken && verification) {
            setIntegratingSocial(true);
            setError(undefined);
            try {
                const token = await getAccessTokenSilently();
                const resp = await fetch(
                    API_SERVER +
                        `social-integration/?access_token=${discordAccessToken}&twitter_access_token=${token}&hash=${verification.hash}&address=${verification.address}`,
                    { method: "POST" }
                );
                const json = await resp.json();
                if (resp.ok) {
                    setSocialIntegration(json);
                } else {
                    setError(json.errors[0].message);
                }
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setIntegratingSocial(false);
            }
        }
    };

    return {
        loading,
        discordIntegration,
        socialIntegration,
        error,
        setError,
        verification,
        discordAccessToken: discordAccessToken,
        onVerify,
        onIntegrateDiscord,
        integratingDiscord,
        onIntegrateSocial,
        integratingSocial,
    };
};

export default useVerifyState;
