import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useEthers } from "@usedapp/core";
import { FaCheck, FaDiscord, FaEthereum } from "react-icons/fa";

import ConnectWalletButton from "../components/ConnectWalletButton";
import Dot from "../components/Dot";
import ErrorText from "../components/ErrorText";
import Loading from "../components/Loading";
import useVerifyState, { VerifyState } from "../hooks/useVerifyState";
import { getConnection } from "../utils/auth0-utils";

const Verify = () => {
    const { isLoading } = useAuth0();
    const state = useVerifyState();
    return (
        <div className={"container mx-auto mt-44 pt-24"}>
            <span className={"title-xl"}>Verify your account</span>
            <Dot />
            <div className={"body mt-8 mb-16"}>
                Verify your ethereum & social network accounts to get new roles in the discord.
            </div>
            {isLoading && <Loading />}
            {!isLoading && (
                <div className={"mt-24 block md:flex"}>
                    <GetSchwifty state={state} />
                    <RiSeolJu state={state} />
                </div>
            )}
            <ErrorText message={state.error} />
        </div>
    );
};

const RiSeolJu = ({ state }: { state: VerifyState }) => {
    return (
        <div className={"w-full ml-0 md:ml-8 mt-16 md:mt-0"}>
            <div className={"subtitle"}>Become a &quot;Ri, Seol-ju&quot;</div>
            <div className={"note mt-4"}>Integrate your levx.eth subdomain.</div>
            <VerifyAddress state={state} />
            <DiscordSignInButton state={state} />
            <IntegrateDiscordButton state={state} />
        </div>
    );
};

const GetSchwifty = ({ state }: { state: VerifyState }) => {
    return (
        <div className={"w-full mr-0 md:mr-8"}>
            <div className={"subtitle"}>Become a &quot;Get Schwifty!&quot;</div>
            <div className={"note mt-4"}>Sign in with both discord & twitter, that&apos;s it.</div>
            <VerifyAddress state={state} />
            <DiscordSignInButton state={state} />
            <TwitterSignInButton state={state} />
            <IntegrateSocialButton state={state} />
        </div>
    );
};

const VerifyAddress = ({ state }: { state: VerifyState }) => {
    const { account } = useEthers();
    return (
        <div className={"mt-8"}>
            {!state.verification && !account && <ConnectWalletButton className={"btn btn-lg w-full"} />}
            {!state.verification && account && (
                <button className={"btn btn-lg w-full"} onClick={state.onVerify}>
                    <FaEthereum className={"mr-2"} /> Step 0: Ethereum address
                </button>
            )}
            {state.verification && <Complete />}
        </div>
    );
};

const DiscordSignInButton = ({ state }: { state: VerifyState }) => {
    const { user, loginWithRedirect } = useAuth0();
    return (
        <div className={"mt-4 items-center"}>
            {!state.verification && (
                <button className={"btn btn-lg btn-outline w-full"} disabled={true}>
                    Complete Step 0 first
                </button>
            )}
            {state.verification && !user && (
                <button
                    className={"btn btn-lg btn-discord w-full"}
                    onClick={() => loginWithRedirect({ redirectUri: location.href, connection: "discord" })}
                >
                    <FaDiscord fontSize={24} className={"mr-2"} /> Step 1: Sign in with Discord
                </button>
            )}
            {state.verification && user && <Complete />}
        </div>
    );
};

const TwitterSignInButton = ({ state }: { state: VerifyState }) => {
    const { user, loginWithRedirect } = useAuth0();
    const connection = getConnection(user);
    const signedIn = user && connection == "twitter";
    return (
        <div className={"mt-4 flex items-center"}>
            {(!state.verification || !state.discordAccessToken) && (
                <button className={"btn btn-lg btn-outline w-full"} disabled={true}>
                    Complete Step 1 first
                </button>
            )}
            {state.verification && state.discordAccessToken && !signedIn && (
                <button
                    className={"btn btn-lg btn-twitter w-full"}
                    onClick={() => loginWithRedirect({ redirectUri: location.href, connection: "twitter" })}
                >
                    <FaDiscord fontSize={24} className={"mr-2"} /> Step 2: Sign in with Twitter
                </button>
            )}
            {state.verification && state.discordAccessToken && signedIn && <Complete />}
        </div>
    );
};

const IntegrateDiscordButton = ({ state }: { state: VerifyState }) => {
    const { user } = useAuth0();
    const ready = state.verification && user;
    return (
        <div className={"mt-4 flex items-center"}>
            {!ready && (
                <button className={"btn btn-lg btn-outline w-full"} disabled={true}>
                    Complete Step 1 first
                </button>
            )}
            {ready && !state.discordIntegration && (
                <button className={"btn btn-lg w-full"} onClick={state.onIntegrateDiscord}>
                    {state.integratingDiscord ? "Becoming a 'Ri, Seol Ju'..." : "Step 2: Become a 'Ri, Seol Ju'"}
                </button>
            )}
            {ready && state.discordIntegration && (
                <div className={"w-full"}>
                    <button className={"btn btn-lg btn-outline w-full mb-4"} disabled={true}>
                        <FaCheck className={"mr-2"} />{" "}
                        {`You're now a Ri, Seol Ju (${state.discordIntegration.domain}.levx.eth)`}
                    </button>
                    {getConnection(user) == "discord" && <DiscordSignOutButton />}
                    {getConnection(user) == "twitter" && <TwitterSignOutButton />}
                </div>
            )}
        </div>
    );
};

const IntegrateSocialButton = ({ state }: { state: VerifyState }) => {
    const { user } = useAuth0();
    const ready = state.verification && state.discordAccessToken && getConnection(user) == "twitter";
    return (
        <div className={"mt-4 flex items-center"}>
            {!ready && (
                <button className={"btn btn-lg btn-outline w-full"} disabled={true}>
                    Complete Step 2 first
                </button>
            )}
            {ready && !state.socialIntegration && (
                <button className={"btn btn-lg w-full"} onClick={state.onIntegrateSocial}>
                    {state.integratingSocial ? "Becoming a 'Get Schwifty!'..." : "Step 3: Become a 'Get Schwifty!'"}
                </button>
            )}
            {ready && state.socialIntegration && (
                <div className={"w-full"}>
                    <button className={"btn btn-lg btn-outline w-full mb-4"} disabled={true}>
                        <FaCheck className={"mr-2"} /> You&apos;re now a &apos;Get Schwifty!&apos;
                    </button>
                    <TwitterSignOutButton />
                </div>
            )}
        </div>
    );
};

const Complete = () => (
    <button className={"btn btn-lg btn-outline w-full"} disabled={true}>
        <FaCheck className={"mr-2"} /> Complete
    </button>
);

const DiscordSignOutButton = () => {
    const { user, logout } = useAuth0();
    return (
        <button
            className={"btn btn-lg btn-discord btn-discord-outline w-full"}
            onClick={() => logout({ returnTo: location.href })}
        >
            <FaDiscord fontSize={24} className={"mr-2"} /> Sign out ({user?.nickname || user?.name})
        </button>
    );
};

const TwitterSignOutButton = () => {
    const { user, logout } = useAuth0();
    return (
        <button className={"btn btn-lg btn-twitter w-full"} onClick={() => logout({ returnTo: location.href })}>
            <FaDiscord fontSize={24} className={"mr-2"} /> Sign out ({user?.nickname || user?.name})
        </button>
    );
};

export default Verify;
