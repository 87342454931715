import React, { useState } from "react";

import { RiMenuLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import AccountButton from "./AccountButton";

const Header = (props: { multiplier: number }) => {
    const [open, setOpen] = useState(false);
    let multiplier = props.multiplier;
    if (multiplier < 1) multiplier = 1;
    if (multiplier > 1.5) multiplier = 1.5;
    const size = 80 * multiplier;
    const opacity = (1.5 - multiplier) * 2;
    return (
        <div className={"fixed top-0 w-full h-auto z-10 font-serif bg-primary"}>
            <div className={"container mx-auto my-6 flex justify-between"}>
                <div className={"flex items-center"}>
                    <Link to={"/"} className={"hidden md:inline-block"}>
                        <Portrait size={size} />
                    </Link>
                    <DesktopLogo multiplier={multiplier} opacity={opacity} />
                    <button className={"block md:hidden px-1 py-2 mt-1 mr-4"} onClick={() => setOpen(true)}>
                        <RiMenuLine fontSize={20} />
                    </button>
                    <Link to={"/"} className={"block md:hidden"}>
                        <div className={"text-2xl"}>Oh..Geez</div>
                    </Link>
                </div>
                <Menu />
                {open && <MobileMenu onNavigate={() => setOpen(false)} />}
            </div>
        </div>
    );
};

const Portrait = ({ size }: { size: number }) => {
    return (
        <div className={"relative"} style={{ height: size, width: size }}>
            <img className={"w-full h-full"} src={"img/icon.png"} alt={"icon"} />
        </div>
    );
};

const DesktopLogo = ({ multiplier, opacity }: { multiplier: number; opacity: number }) => {
    return (
        <div className={"hidden md:block ml-0 md:ml-6"} style={{ fontSize: 1.75 * multiplier + "rem" /*, opacity*/ }}>
            Oh..Geez
        </div>
    );
};

const Menu = () => {
    return (
        <div className={"flex items-center h-16"}>
            {/*<Link to={"/ogz"} className={"hidden md:block text-xl mr-12"}>
                OGZ
            </Link>*/}
            <AccountButton />
        </div>
    );
};

const MobileMenu = ({ onNavigate }: { onNavigate: () => void }) => {
    return (
        <div
            className={"fixed top-0 right-0 bottom-0 left-0 bg-primary z-20 flex flex-col justify-center items-center"}
        >
            <Link to={"/distributions"} className={"text-3xl mt-8"} onClick={onNavigate}>
                Rewards
            </Link>
            <Link to={"/domains"} className={"text-3xl mt-8"} onClick={onNavigate}>
                Domains
            </Link>
            <Link to={"/ve-levx"} className={"text-3xl mt-8"}>
                THANO$
            </Link>
            <Link to={"/zero"} className={"text-3xl mt-8"} onClick={onNavigate}>
                ZERO
            </Link>
        </div>
    );
};

export default Header;
