import React from "react";

const Footer = () => {
    return (
        <div className="mt-64">
            <div className="container mx-auto h-32 md:flex items-center justify-between">
                <div className="w-full md:w-1/3">
                    <a href="/#/" className={"flex"}>
                        <img className={"w-20 h-11 mr-2"} src={"img/logo.png"} alt={"icon"} />
                    </a>
                </div>
                <div className="w-full md:w-1/3 flex justify-end md:justify-center text-xs md:text-sm">
                    <a href={"https://mirror.xyz/ohgeez.eth"} target={"_blank"} className={"mr-4"} rel="noreferrer">
                        BLOG
                    </a>
                    <a href={"https://twitter.com/LevXDAOhGeez"} target={"_blank"} className={"mr-4"} rel="noreferrer">
                        TWITTER
                    </a>
                    <a href={"https://discord.gg/ohgeez"} target={"_blank"} className={"mr-4"} rel="noreferrer">
                        DISCORD
                    </a>
                    <a href={"https://github.com/ohgeez-dao"} target={"_blank"} className={""} rel="noreferrer">
                        GITHUB
                    </a>
                </div>
                <div className="w-full md:w-1/3 text-left text-right text-xs md:text-md leading-snug py-4">
                    <div>this is all jokes</div>
                    <div>don&apos;t take it too seriously</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
