import { User } from "@auth0/auth0-react";

export const getConnection = (user?: User, capitalize?: boolean) => {
    let parsed = user?.sub?.split("|")?.[0] || "";
    if (parsed == "oauth2") parsed = user?.sub?.split("|")?.[1] || "";
    if (capitalize) {
        return parsed[0].toUpperCase() + parsed.substring(1);
    }
    return parsed;
};
