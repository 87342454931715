import React from "react";

const ErrorText = (props: { message?: string }) => {
    return (
        <div>
            {props.message && !props.message.includes("User denied") && props.message !== "Forbidden" && (
                <div className={"body text-error mt-4"}>{props.message}</div>
            )}
        </div>
    );
};

export default ErrorText;
