import React, { useEffect } from "react";

import { shortenAddress, useEthers, useLookupAddress } from "@usedapp/core";

import ConnectWalletButton from "./ConnectWalletButton";

const AccountButton = () => {
    const { isLoading, account, chainId, deactivate, switchNetwork } = useEthers();
    const ens = useLookupAddress();

    const onClick = () => {
        if (confirm("Do you want to disconnect?")) {
            deactivate();
        }
    };

    useEffect(() => {
        if (chainId != 1) switchNetwork(1);
    }, [chainId]);

    return account ? (
        <button
            onClick={onClick}
            className={
                "text-base md:text-xl px-4 py-2 md:py-4 leading-none rounded" +
                (!account ? " border hover:backdrop-contrast-100" : "")
            }
            disabled={isLoading}
        >
            <span>{ens ?? shortenAddress(account)}</span>
        </button>
    ) : (
        <ConnectWalletButton onError={alert} />
    );
};

export default AccountButton;
